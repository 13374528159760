import React from 'react'
import { Link } from 'gatsby'
import headerStyles from './header.module.css'
import MainLogo from "../media/logo3.svg"

const Header = () => {

    return(
        <header>
            <nav>
                <Link className={headerStyles.navItemLogo} to='/'>
                    <MainLogo className={headerStyles.indexLogoImg} />
                </Link>
                <ul className={headerStyles.navMenu}>
   
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/presentation'>Presentation</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/openOffice'>Open Office</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/events'>Events</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/visit'>Visit</Link>
                    </li>
           
                </ul>
            </nav>
        </header>
    )
}

export default Header